<template>
    <el-menu :default-active="activeIndex" class="el-menu-demo hidden-sm-and-down" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1"><router-link to="/">Home</router-link></el-menu-item>
        <el-menu-item index="2"><router-link to="/about">About</router-link></el-menu-item>
    </el-menu>
    <el-row type="flex" class="hidden-md-and-up">
        <el-dropdown trigger="click">
            <span class="el-dropdown-link">
                Menu<i class="el-icon-menu el-icon--right"></i>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item><router-link to="/">Home</router-link></el-dropdown-item>
                    <el-dropdown-item divided><router-link to="/about">About</router-link></el-dropdown-item>
                    <el-dropdown-item disabled>Disabled</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>       
    </el-row>
    <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
