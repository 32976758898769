import axios from 'axios';

export function getAllTasks() {
    return axios.get(`${process.env.VUE_APP_API_URL}tasks`, {
        'Content-Type': 'application/json',
    });
}

export function postTask(data) {
    return axios.post(`${process.env.VUE_APP_API_URL}tasks`, data, {
        'Content-Type': 'application/json',
    });
}

export function updateTask(id, data) {
    return axios.put(`${process.env.VUE_APP_API_URL}tasks/${id}`, data, {
        'Content-Type': 'application/json',
    });
}

export function deleteTask(id) {
    return axios.delete(`${process.env.VUE_APP_API_URL}tasks/${id}`, {
        'Content-Type': 'application/json',
    });
}