<template>
  <div class="home">
    <h1>To-Do <i class="el-icon-check"></i></h1>
    <ToDo :todo="todo" @addTask="addTask" @removeTask="removeTask" @checkAll="checkAll" @checkTask="checkTask"/>
  </div>
</template>

<script>
    import io from 'socket.io-client';
    import ToDo from '@/components/ToDo.vue';
    import { getAllTasks, postTask, updateTask, deleteTask } from '@/services/task.service';

    let socket = io.connect('https://protopwa-api.marge-labo.com/');

    export default {
        name: 'Home',
        components: {
            ToDo
        },
        data: () => ({
            todo: [],
        }),
        async mounted() {
            socket.on('task:new', (data) => {
                this.todo.push(data);
            })
            socket.on('task:remove', (data) =>{
                this.todo.splice(data, 1);
            })
            socket.on('task:check', (data) => {
                this.todo[data.id].checked = data.checked;
            })
            socket.on('task:checkAll', (data) => {
                this.todo[data.i].checked = !data.checked;
            })
            try {
                let todoData = await getAllTasks();
                this.todo = todoData.data;
                localStorage.todoData = JSON.stringify(this.todo);
            } catch (e) {
                console.log(e);
                this.todo = JSON.parse(localStorage.todoData);
            }
        },
        methods: {
            persist() {
                localStorage.todo = JSON.stringify(this.todo);
                console.log(JSON.parse(localStorage.todo));
            },
            addTask(newTask) {
                socket.emit('addTask', { description: newTask, checked: false });
                postTask({ description: newTask, checked: false })
                .catch(function(){
                    let loop = setInterval(function() {
                        postTask({ description: newTask, checked: false }).then(function(){
                            clearInterval(loop);
                        });
                    }, 10000);
                });
                this.persist();
            },
            removeTask(index) {
                deleteTask(this.todo[index].id);
                socket.emit('removeTask', index);
            },
            checkTask(id) {
                // copy infos
                const checked = !this.todo[id].checked;
                socket.emit('checkTask', {id, checked});
                console.log(this.todo);
                const newTask = {};
                newTask.id = this.todo[id].id;
                newTask.description = this.todo[id].description;
                newTask.checked = checked;
                // update db
                updateTask(this.todo[id].id, newTask);
            },
            checkAll(checked) {
                for(var i=0;i < this.todo.length; i++ ) {
                    this.todo[i].checked = !checked;
                    socket.emit('checkAllTask', {i, checked});
                    updateTask(this.todo[i].id, this.todo[i]);
                }
            }
        }
    }
</script>
