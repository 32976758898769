<template>
    <el-row type="flex" justify="center">
        <el-col :span="24">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header">
                        <span>Ma liste</span>
                        <el-checkbox class="button" v-model="allChecked" :label="allChecked ? 'Uncheck all':'Check all'" @click="$emit('checkAll', allChecked)" border>
                        </el-checkbox>
                    </div>
                </template>
                <el-row type="flex">
                    <el-form :inline="true">
                        <el-form-item label="New task">
                            <el-input placeholder="Enter something..." v-model="newTask" suffix-icon="el-icon-date" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="button" type="primary" round @click="$emit('addTask', this.newTask)">
                                <i class="el-icon-plus el-icon--left"></i>
                                Ajouter
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-row>                
                <el-collapse v-model="activeNames">
                    <el-collapse-item name="1">
                        <template #title>
                            <el-button class="button" type="text">Close<i class="el-icon-minus el-icon--right"></i></el-button>
                        </template>
                        <div v-for="item, i in todo" :key="i" class="text item">
                            <el-checkbox v-model="item.checked" @click="$emit('checkTask', i)">{{ item.description }}</el-checkbox>
                            <el-button type="danger" icon="el-icon-delete" circle class="hidden-md-and-up" @click="$emit('removeTask', i)"></el-button>
                            <el-button type="danger" icon="el-icon-delete" round class="hidden-sm-and-down" @click="$emit('removeTask', i)">Supprimer</el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import { defineComponent, ref } from 'vue';
    export default defineComponent({
        name: 'ToDo',
        emits: ['addTask', 'removeTask', 'checkAll', 'checkTask'],
        props: {
            todo: Array,
        },
        data() {
            return {
                activeNames: ['1'],
                newTask: "",
                allChecked: false,
            }
        },
        setup() {
            return {
                input: ref('')
            }
        },
    });
</script>
<style>
    .item {
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .el-input{
        width:100%;
    }
</style>